import debounce from 'debounce'
import isEmptyObject from 'is-empty-object'
import last from 'last'

import { getActiveCart } from '@/redux/cart/selectors'
import api from 'api'
import { underMinimumMessage } from 'helpers/alerts'
import { defaultCallback } from 'helpers/callbacks'
import { EAZE_ORIGIN } from 'helpers/constants'
import { CASH_PAYMENT_METHOD } from 'helpers/payment'
import promisify from 'helpers/pify'
import checkoutActionTypes from 'redux/checkout/actionTypes'
import { quoteLoaded, quoteLoading, taxFeesLoading, taxFeesLoaded } from 'redux/loading/actions'
import { getDepotMinimumOrder } from 'redux/location/selectors'
import { getActivePaymentId, getDriverTip } from 'redux/payments/selectors'
import { resetPromo, toggleRemovedPromo } from 'redux/promo/actions'

import t from './actionTypes'

/**
 * Use this to reset quote when carts are in invalid states (e.g. less than $25)
 */
export function clearQuote() {
  return {
    type: t.CLEAR_QUOTE
  }
}

export function setQuote(quote) {
  return {
    type: t.SET_QUOTE,
    payload: quote
  }
}

const lastRequestQuote = last(requestQuoteWrapper)
// Add a really large debounce to quote request because this is an expensive operation
// on the backend api and we don't need to make a request for every cart change if they
// happen rapidly
const apiQuoteRequest = debounce(api.requestQuote, 1000)

function requestQuoteWrapper(cartId, promoCode = '', paymentMethod, driverTip = 0, origin = EAZE_ORIGIN) {
  const payload = {
    cartId,
    paymentMethod,
    driverTip,
    origin
  }

  if (promoCode !== '') {
    payload.promoCode = promoCode
  }
  return promisify(apiQuoteRequest)(payload)
}

export function receiveQuote(quote) {
  return (dispatch, getState) => {
    dispatch(quoteLoaded())
    const cart = getActiveCart(getState())
    // when using a debounced receiveQuote, it's possible to load an old quote after there are
    // no items in the cart. In this case, reset the quote instead.
    if (!cart.products.length) {
      dispatch(clearQuote())
    } else {
      quote.lastQuoteTime = new Date().toISOString()
      dispatch(setQuote(quote))
    }
  }
}

/**
 * Get pricing and tax information for a given cart and address.
 * @param  {Function} callback Node style callback with (err, res) args
 */
export function requestQuote(callback = defaultCallback, forceLoading = false) {
  return (dispatch, getState) => {
    const state = getState()
    const { location, promo, user } = state
    const cart = getActiveCart(state)

    const address = location.activeLocation
    const depotMinimumOrder = getDepotMinimumOrder(state)
    const minimumPriceMessage = `$${depotMinimumOrder}.00 order minimum`
    const activePaymentId = getActivePaymentId(state)
    const driverTip = activePaymentId === CASH_PAYMENT_METHOD ? 0 : getDriverTip(state)

    let promoCode = ''
    if (promo.code !== '') {
      promoCode = promo.code
    }

    // bail if the user isn't logged in!
    // this route requires auth
    if (!user.userId || !user.xAuthToken) {
      dispatch(clearQuote())
      return callback()
    } else {
      // this does not need to be called if user is logged out
      // loading state for when cart is edited (ex. when item is removed)
      // makes call to quotes to recalculate tax + fees
      dispatch(taxFeesLoading())
    }

    if (isEmptyObject(address) || !cart.id) {
      dispatch(clearQuote())
      return callback()
    }

    if (forceLoading || isEmptyObject(promo)) {
      dispatch(quoteLoading())
    }

    if (cart.products && cart.products.length) {
      lastRequestQuote(cart.id, promoCode, activePaymentId, driverTip)
        .then((quote) => {
          dispatch(receiveQuote(quote))
          dispatch(taxFeesLoaded())
          return callback(null, quote)
        })
        .catch((err) => {
          if (err.message && err.message.toLowerCase().match(/code/)) {
            dispatch(resetPromo())
            dispatch(toggleRemovedPromo())
            return dispatch(requestQuote(callback, forceLoading))
          } else {
            dispatch(quoteLoaded())

            if (err.message === minimumPriceMessage) {
              dispatch(clearQuote())
              dispatch(setCheckoutError(underMinimumMessage(depotMinimumOrder)))
              return callback(err)
            }

            if (err) {
              dispatch(setCheckoutError(err.message))
              return callback(err)
            }
          }
        })
    } else {
      dispatch(quoteLoaded())
    }
  }
}

// this prevents a madge error for circular dependency
// postOrder in checkout/actions requires requestQuote from quote/actions,
// but requestQuote from quote/actions requires setCheckoutError from checkout/actions
function setCheckoutError(error) {
  return {
    type: checkoutActionTypes.SET_CHECKOUT_ERROR,
    payload: error
  }
}
