// Actions for various application info

import tokenStore from '@eaze/eaze-api-client/es/token-store'
import window from 'global/window'
import Router from 'next/router'

import { track, resetUser } from 'analytics'
import ROUTES from 'helpers/routes'
import { ZendeskAPI } from 'helpers/zendesk-chat'
import { clearCart } from 'redux/cart/actions'
import { setCookie } from 'redux/cookies/actions'

import t from './actionTypes'

import authActionTypes from '../auth/actionTypes'

export function setVersionMismatch() {
  return {
    type: t.VERSION_MISMATCH
  }
}

export function logout(redirectUrl = ROUTES.ROOT) {
  return (dispatch, getState) => {
    tokenStore.set('')
    dispatch(
      setCookie({
        loggedin: false,
        token: '',
        userId: '',
        referrer: '',
        coordinates: '',
        placeId: '',
        cartId: ''
      })
    )

    // clear the zendesk chat widget
    ZendeskAPI('webWidget', 'logout')

    // This will trigger a state = undefined in store.js (we want that)
    dispatch({ type: authActionTypes.LOGOUT })

    // If we have any child apps, they should be using session storage, let's clear that.
    window && window.sessionStorage && window.sessionStorage.clear()
    window && window.localStorage && window.localStorage.clear()
    window && window.__NEXT_REDUX_WRAPPER_STORE__ && window.__NEXT_REDUX_WRAPPER_STORE__.__persistor.purge()

    // if a user tries to log out from the same route we try to direct them to, the page never rehydrates, so we have to reload
    if (Router.router && Router.router.route === redirectUrl) {
      window.location.reload()
    } else {
      Router.push(redirectUrl)
    }
    track('Logout.Success')
    resetUser() // analytics.reset() https://segment.com/docs/getting-started/04-full-install/#using-analyticsreset
    dispatch(clearCart())
  }
}
