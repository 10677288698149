/**
 * Actions for cart updates
 */

import isEmptyObject from 'is-empty-object'
import last from 'last'
import Router from 'next/router'
import qs from 'query-string'
import parallel from 'run-parallel'

import { getActiveCart } from '@/redux/cart/selectors'
import getClientSideProduct from '@bff/handlers/products/client'
import { defaultCallback } from '@helpers/callbacks'
import { DEFAULT_MENU_SLUG, MENU_SLUG } from '@helpers/constants'
import getPath from '@helpers/path'
import { track } from 'analytics'
import api from 'api'
import { Product } from 'bff/models/og/menu'
import errorHandler from 'error-handler'
import { postMessageToReactNative } from 'helpers/native'
import promisify from 'helpers/pify'
import ROUTES from 'helpers/routes'
import { toggleAddress } from 'redux/addressModal/actions'
import { setAlertTypeVisibility, setCurrentAction } from 'redux/alert/actions'
import { ADD_TO_CART, CHECKOUT, alertTypes } from 'redux/alert/config/types'
import { allowAddToCart } from 'redux/alert/selectors'
import { getMenuPayload, getPayloadFromState } from 'redux/analytics/get-payloads'
import checkoutActionTypes from 'redux/checkout/actionTypes'
import { resetEta } from 'redux/eta/actions'
import { cartLoaded, cartLoading, etaLoading, productsLoaded, productsLoading } from 'redux/loading/actions'
import { savePotentialAddress } from 'redux/location/actions'
import { getActiveLocation, getStoreClosed } from 'redux/location/selectors'
import { getIsPhoneVerified } from 'redux/profile/selectors'
import { clearQuote, requestQuote } from 'redux/quote/actions'

import t from './actionTypes'
import { addToCart, removeFromCart } from './cart-update'
import { CartItem, CartState } from './types'

import { setCookieValue } from '../cookies/actions'
import { RootState } from '../reducers'

/**
 * Updates cart products. Used to transitioning cart products from order status page to checkout after an order is canceled.
 * @param  {{id: number, quantity: number}[]} products
 * @param  {Boolean}               update - if true, will initiate new request for additional product information
 */
export function updateItems(products: CartItem[], update = false) {
  return async (dispatch, getState) => {
    // save the current product quantities to the cart
    dispatch(setCartItems({ products }))

    // try to update the cart with the new quantities
    await dispatch(updateCart(products))

    if (!products.length) {
      dispatch(clearQuote())
      dispatch(resetEta())
    }

    // clear any generic errors, since adding can remove cart minimum errors
    // and removing can fix errors related to drivers not having the right inventory
    dispatch({ type: checkoutActionTypes.CLEAR_CHECKOUT_ERROR })

    dispatch(productsLoading())

    if (update) {
      dispatch(requestCartProducts()) // updates cart.productsList
    } else {
      const state = getState()
      const cart = getActiveCart(state)
      const cartProductsList = cart.productsList
      const activeLocation = getActiveLocation(state)
      // At this point if products changed based on the server response in `updateCart` the local `products` will be out of date with `cart.products`
      // so we need to update the local `products` with the new quantities from the state
      const cartProducts = cart.products

      // TODO: INVESTIGATE HERE! PEP-1309
      const newCartProducts = matchedProducts(cartProducts, cartProductsList, activeLocation.depot)
      dispatch(updateProducts(newCartProducts)) // updates cart.productsList
    }
  }
}

/**
 * Update cart products. Called by updateItems to keep items and products in sync
 * @param  {Array[products]}
 */
export function updateProducts(products) {
  return (dispatch) => {
    dispatch(productsLoaded())

    dispatch({
      type: t.UPDATE_CART_PRODUCTS,
      payload: { products, menuSlug: DEFAULT_MENU_SLUG }
    })
  }
}

/**
 * Remove cart item
 * @param {Number} id                  Product id
 * @param {Number} quantity            Number of product to remove from cart
 */
export function removeItem(id, quantity, price, shouldRequestQuote = false) {
  return (dispatch, getState) => {
    // Show eta as loading because we want customers to know we are recalculting
    // eta when they remove an item from the cart in checkout.
    dispatch(etaLoading())

    const state = getState()
    const currentCartItems = getActiveCart(state).products
    const menuPayload = getMenuPayload(state)

    const newCartItems: CartItem[] = removeFromCart(currentCartItems, id, quantity)

    // update items, passing in true as the second param, will later dispatch requestItems.
    dispatch(updateItems(newCartItems, true))

    if (shouldRequestQuote) {
      dispatch(requestQuote())
    }

    const removeFromCartPayload = {
      ...menuPayload,
      'cart.items': newCartItems.map(({ id, quantity }) => ({ id, quantity })),
      'cart.product.id': id
    }

    track('Menu.Product.Remove', removeFromCartPayload)
  }
}

/**
 * add cart item
 * @params {Number} id                            Product ID
 * @params {Number} quantity                      Number of product to remove from cart
 * @params {Number} price                         Price of product
 * @params {{ id, index}} groupTrackingInfo       id = group.ID, index = position of group on page (mainly for /menu)
 * @params {Boolean = false} shouldRequestQuote   Whether to issue a request to /api/quotes
 */
// @TODO add types to this arguments: (CartItem, TrackingMetadata, Boolean)
export function addItem({ id, quantity, price, groupTrackingInfo, shouldRequestQuote = false }) {
  return async (dispatch, getState) => {
    const state = getState()
    const isStoreClosed = getStoreClosed(state)

    if (isStoreClosed) {
      // trigger the action that makes the store closed modal appear
      return dispatch(setCurrentAction(ADD_TO_CART))
    }

    if (shouldRequestQuote) {
      dispatch(requestQuote())
    }

    if (!allowAddToCart(state)) {
      return dispatch(toggleAddress())
    }

    const cart: CartState = getActiveCart(state)
    const currentCartItems = cart.products

    const menuPayload = getMenuPayload(state)
    const newCartItems = addToCart(currentCartItems, id, quantity, price)

    // update with new cart items, with update flag set as true so that we do a new product list request
    await dispatch(updateItems(newCartItems, true))
    const addToCartPayload = {
      ...menuPayload,
      'cart.items': newCartItems.map(({ id, quantity }) => ({ id, quantity })),
      'cart.product.id': id
    }
    if (groupTrackingInfo) {
      addToCartPayload['group.id'] = groupTrackingInfo.id
      addToCartPayload['group.pageIndex'] = groupTrackingInfo.index
    }

    track('Menu.Product.Add', addToCartPayload)
  }
}

/**
 * Dispatch request for product information for cart items
 * @param  {Array[{id, quantity}]} Cart items to get product listings for
 */
export function requestCartProducts() {
  return (dispatch, getState) => {
    const state = getState()
    const cart = getActiveCart(state)
    const activeLocation = getActiveLocation(state)
    const menuProducts = state.products.collection

    dispatch(cartLoading())

    dispatch({
      type: t.REQUEST_CART_PRODUCTS
    })

    if (cart.products && !cart.products.length) {
      dispatch(cartLoaded())
      return
    }

    // Create api functions to get product information
    const itemIdSet = cart.products?.reduce((itemIds: { [id: number]: boolean }, item) => {
      itemIds[item.id] = true
      return itemIds
    }, {})

    const findOrGetSingleProduct = (id) => {
      return (callback) => {
        const menuProduct = menuProducts[id]
        if (menuProduct) {
          callback(null, menuProduct)
        } else {
          getClientSideProduct({ productId: id, placeId: activeLocation?.id, menuSlug: DEFAULT_MENU_SLUG }).then(
            (res) => {
              const { err, data } = res
              if (err) {
                callback(new Error(`Error finding/getting single product: ${err.message}`), data)
              } else {
                callback(null, data)
              }
            }
          )
        }
      }
    }

    const cartItemLookups = Object.keys(itemIdSet).map(findOrGetSingleProduct)

    // Send the product lookups in parallel and set the cart products when they return
    // @TODO: replace all this with a Promise.all
    parallel(cartItemLookups, (err, fullProductList) => {
      if (err) return errorHandler(new Error(`Error with product lookups: ${err.message}`))
      dispatch(cartLoaded())
      dispatch(receiveProducts(cart.products, fullProductList, activeLocation.depot))
    })
  }
}

function receiveProducts(cartItems, menuProducts, depot) {
  // copy quantities from cart items into the product list to avoid double for loops
  // for anything that requires quantity information and product information
  const mappedProducts = matchedProducts(cartItems, menuProducts, depot)
  return (dispatch) => {
    dispatch(updateProducts(mappedProducts))
  }
}

/**
 * This function does 2 things - it copies quantities from items to products, and
 * it prunes products without a matching item. This should be used to keep items
 * and products in sync, both on adding/removing from cart and after getting
 * product information from the API
 * @param  {[Array]} cartProducts    - cart items - {id, quantity}
 * @param  {[Array]} cartProductsList - objects containing id, price array, display name, weight, etc of products

 * @return {[Array]} products - objects returned have quantity from item
 */
function matchedProducts(cartProducts, cartProductsList, depot) {
  const mappedProducts = []

  // abort all actions if cart is empty and redirect to menu
  if (!cartProducts.length) {
    return Router.push(ROUTES.MENU)
  }

  cartProducts.forEach((item) => {
    // using basic for loop so that we can break out of the loop once we find the product
    for (let j = 0; j < cartProductsList.length; j++) {
      const product = cartProductsList[j]
      if (product && item.id === product.id) {
        // make sure we're not matching the same product for repeated cart items
        if (mappedProducts.indexOf(product) > -1) continue

        const foundProduct = { ...product }
        foundProduct.quantity = item.quantity
        mappedProducts.push(foundProduct)
        break
      }
    }
  })

  // TODO: Remove logging after investigation
  if (cartProductsList.length !== mappedProducts.length) {
    // If we hit this, we've removed items from the cart, but why?
    // Probably https://github.com/eaze/eaze.com/issues/6697
    const trackInfo = {
      depot,
      clientProducts: cartProducts,
      serverProducts: cartProductsList
    }

    track('Cart.Mismatch', trackInfo)
    errorHandler(new Error('PEP-1309'), trackInfo)
  }

  return mappedProducts
}

export function isCartError(err) {
  return err.message && err.message.match(/expired|deleted|ordered/)
}

export function handleCartError(err, dispatch) {
  if (isCartError(err)) {
    // has the cart expired? let's tell the user.
    dispatch(setExpiredCart(true))
    return dispatch(fetchCart(true))
  } else if (err && err.statusCode === 409) {
    // too many requests fired, let's refetch our cart
    return dispatch(fetchCart())
  }

  return errorHandler(new Error(err.message))
}

const fetchCartApiPromise = promisify(api.fetchCart)

// fetchCart is used when a user has not yet been assigned a cart. This is called during login (with an empty client side cart)
// and also used when the page is refreshed for an authenticated user. If the cart doesn't exist, a new one will be created.
// isExpired, comes in when the previous cart has expired. We're basically, just going to hide the expired modal here.
export function fetchCart(isExpired = false, callback = defaultCallback, locationId?, createEmptyCart = false) {
  return (dispatch, getState) => {
    dispatch(cartLoading())

    const state: RootState = getState()

    const cart: CartState = getActiveCart(state)

    const {
      checkout: { potentialAddress },
      location: { activeLocation },
      user: { userId }
    } = state

    const { id: cartId, products } = cart

    const cartLength = products.length
    const location = locationId || activeLocation.id

    if ((!cartId && cartLength) || isExpired) {
      // if we don't have a cart.id but we have items in our cart, let's create a new cart.
      // createNewCart will POST the products and set them in our server side cart
      // should only execute if a user modifies their cart, before logging in.
      return dispatch(createNewCart(null, {}, null, callback))
    }

    // for server side cart creation, we _need_ an activeLocation.id and userId
    if (!location || !userId) {
      return
    }

    const payload = { userId, location, menu: DEFAULT_MENU_SLUG }

    return fetchCartApiPromise(payload)
      .then(async (res) => {
        if (!isEmptyObject(potentialAddress)) {
          dispatch(savePotentialAddress({}))
        }

        // if cartLength is > 0, use the current cart, and update the existing user's cart to the new cart.
        if (cartLength) {
          dispatch(setCartId(res))
          await dispatch(updateItems(cart.products, true))
          // Option to skip adding items to cart if we want an empty cart
        } else if (createEmptyCart) {
          dispatch(setCartId(res))
          return callback()
          // Otherwise use any nonexpired cart. No need to set the user's.
        } else if (!isExpired) {
          dispatch(setCartItems(res))
        }

        dispatch(setCartId(res))
        callback()
      })
      .catch((err) => handleCartError(err, dispatch))
  }
}

// createNewCart is used when a user has modified their client side cart, and then authenticated. This will create a new server
// side cart, with the products they've already selected. (this method is nearly identical as fetchCart, but accounts for sending
// an existing cart)
export function createNewCart(
  isEmpty,
  cartPayload = {},
  placeId = null,
  callback = defaultCallback,
  createEmptyCart = false
) {
  const createNewCartPromise = promisify(api.createNewCart)
  return (dispatch, getState) => {
    dispatch(cartLoading())

    const state = getState()
    const cart = getActiveCart(state)
    const products = cart.products
    const {
      location: { activeLocation },
      user: { userId }
    } = state
    const locationId = placeId || activeLocation.id

    if (!locationId || !userId) return

    const payload = {
      userId,
      location: locationId,
      products: isEmpty ? [] : products,
      menu: DEFAULT_MENU_SLUG,
      ...cartPayload
    }

    return createNewCartPromise(payload)
      .then((res) => {
        dispatch(setCartId(res))
        dispatch(setExpiredCart(false))
        if (!createEmptyCart) {
          dispatch(setCartItems(res))
        }
        callback()
      })
      .catch((err) => errorHandler(new Error(err.message)))
  }
}

// called after fetchCart or createNewCart, saving the cart id in our cart store
// this is used when updating the cart
export function setCartId(cart) {
  return (dispatch) => {
    setCookieValue('cartId', cart.id)
    dispatch(saveCartId(cart))
  }
}

export function saveCartId(cart) {
  return {
    type: t.SET_CART_ID,
    payload: {
      id: cart.id,
      menuSlug: cart.menu
    }
  }
}

// only call CART patch at most, every 0.25 seconds
// const patchCart = debounce(api.updateCart, 250)
const createUpdateCartApiPromise = last(promisify(api.updateCart))

/**
 * updateCart is used to PATCH new carts to the server. This is called whenever the cart is modified (added to or removed from)
 */
function updateCart(cartItems) {
  return (dispatch, getState) => {
    const state = getState()
    const {
      user: { userId }
    } = state
    const menuSlug = MENU_SLUG
    const cart = getActiveCart(state)
    const location = getActiveLocation(state)

    if (!userId) return // only PATCH if we're authenticated

    // cartLength determines whether or not the server modified what we sent.
    // server can remove items if they've become unavailable
    const cartLength = cartItems.length

    return createUpdateCartApiPromise({ products: cartItems, id: cart.id, menu: menuSlug })
      .then((res) => {
        // the BE and FE cart location can get mismatched, especially when switching devices
        // if this happens, lets create a new cart to get them back in sync
        if (res.location !== location.id) {
          return dispatch(createNewCart(null, {}, null))
        }
        // if cartLength > res.products.length then products have been removed on the server
        // let's trigger the your cart was updated modal
        if (res.id === cart.id && res.products.length < cartLength && cartLength > 0) {
          dispatch(cartSizeMismatch(true))
        }

        dispatch(setCartId(res))
        dispatch(setCartItems(res)) // updates cart.products
      })
      .catch((err) => handleCartError(err, dispatch))
  }
}

// used for setting cart.products in the reducer
function setCartItems(res) {
  return (dispatch) =>
    dispatch({
      type: t.SET_CART_ITEMS,
      payload: {
        products: res.products,
        menuSlug: DEFAULT_MENU_SLUG
      }
    })
}

// cart has expired! This will trigger a modal alerting the user, and async we've began creating a new cart for them.
export function setExpiredCart(isExpired) {
  return (dispatch) => {
    if (isExpired) dispatch(setAlertTypeVisibility(alertTypes.CART_EXPIRED))

    dispatch({
      type: t.CART_EXPIRED,
      payload: {
        value: isExpired,
        menuSlug: DEFAULT_MENU_SLUG
      }
    })
  }
}

// this will trigger a modal stating that the users cart has been modified server side. We've already set the client side
// cart to reflect it. (this could occur if a product has become unavailable)
export function cartSizeMismatch(value) {
  return (dispatch) => {
    dispatch(setAlertTypeVisibility(alertTypes.CART_SIZE_MISMATCH))
    dispatch({
      type: t.CART_SIZE_MISMATCH,
      payload: {
        value,
        menuSlug: DEFAULT_MENU_SLUG
      }
    })
  }
}

export function clearCart() {
  return {
    type: t.CLEAR_CART
  }
}

export function clearCartMessage(menuSlug) {
  return {
    type: t.CLEAR_CART_MESSAGE,
    payload: {
      menuSlug
    }
  }
}

// add price to the cart.items object, which is used for tracking
function addPriceToCartItems(cartItems: CartItem[], products: Product[]) {
  cartItems.forEach((item) => {
    const id = item.id
    if (products[id]) {
      item.price = products[id].price
    } else {
      item.price = null
    }
  })
  return cartItems
}

export function goToCartDrawer(baseUrl) {
  return (dispatch, getState) => {
    const state = getState()
    const cart = getActiveCart(state)
    const menuPayload = getPayloadFromState(state)
    const activeLocation = getActiveLocation(state)

    // Check if activeLocation is set, if none toggle
    if (!activeLocation || !activeLocation.id) {
      dispatch(toggleAddress())
      return
    }

    const cartItems = addPriceToCartItems(cart.products, state.products.collection)
    const checkoutPayload = Object.assign({}, menuPayload, {
      'cart.items': cartItems
    })

    track('Menu.Checkout', checkoutPayload)

    // if your phone isn't verified, and you have a user id,
    // stop! and show them the phone verif modal.
    if (!getIsPhoneVerified(state) && state.user.userId) {
      dispatch(setCurrentAction(CHECKOUT))
      return dispatch(setAlertTypeVisibility(alertTypes.VERIFY_PHONE, true))
    }

    if (baseUrl) {
      // baseUrl comes from concierge cart
      baseUrl.redirectUrl = `${baseUrl.pathname}&cart=${cart.id}`
    }

    const postMessageItems = cart.products.reduce((acc: { catalogItemId: number; quantity: number }[], item) => {
      const cid =
        !isEmptyObject(state.products.collection) &&
        state.products.collection[item.id] &&
        state.products.collection[item.id].catalogItemId

      if (cid) {
        acc.push({ catalogItemId: cid, quantity: item.quantity })
      }

      return acc
    }, [])

    const postMsgPayload = {
      placeId: state.location.activeLocation && state.location.activeLocation.id,
      products: postMessageItems
    }

    window.postMessage(JSON.stringify(postMsgPayload), window.location.origin)
    postMessageToReactNative(postMsgPayload)

    const { href, asPath } = getPath(baseUrl || Router, { addParams: { cart: cart.id } })

    Router.push(href, asPath, { shallow: true })
  }
}

export function goToCheckout() {
  return (dispatch, getState) => {
    const state = getState()
    const {
      user: { userId }
    } = state
    // if not logged in, bump the user to login with a redirectUrl param of checkout
    if (!userId) {
      return Router.push(`${ROUTES.LOGIN}?${qs.stringify({ redirectUrl: ROUTES.CHECKOUT })}`)
    } else {
      return Router.push(ROUTES.CHECKOUT)
    }
  }
}
